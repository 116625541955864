.analyticstable {

	&.bp3-html-table th:not(:first-child) {
		text-align: center !important;
		white-space: nowrap;
	}
	
	&.bp3-html-table tbody td:not(:first-child) {
		white-space: nowrap;
		text-align: right;
	}
	
	&.bp3-html-table tr td:first-child {
		border-left: 1px solid #aaa;
	}
	
	&.bp3-html-table tbody tr:last-child td {
		border-bottom: 1px solid #aaa;
	}
	
	&.bp3-html-table tbody tr:hover {
		background-color: #ddd;
	}

	&.bp3-html-table tr td:last-child {
		border-right: 1px solid #aaaaaa37;
	}

	.sum td {
		font-weight: bolder;
		border-top: 2px solid #aaa;
	}

	.dbnegativ {
		background-color: #eb9a977d !important;
	}

	div.not-active {
		opacity: 0.2;
	}

	&.bp3-html-table tbody tr:last-child th:not(th:first-child) {
		text-align: right !important;
	}
}

$confirmed-color: #c3f7c073;
$proposed-color: #ffe9a2a4;
$cancelled-color: #ffb4aa8e;

.overviewlegend {
	float:right;

	> div {
		display: inline-block;
		margin-right: 1em;
		vertical-align: middle;
	}
	> div > span {
		display: inline-block;
		width: 16px;
		height: 16px;
		margin-right: 0.5em;
		border: 1px solid black;
	}
	label {
		height: 16px;
		display: inline-block;
		vertical-align: top;
	}
	.confirmed {
		background-color: $confirmed-color;
	}
	.proposed {
		background-color: $proposed-color;
	}
	.cancelled {
		background-color: $cancelled-color;
	}
}

.overviewdetails {
	margin: 0px 1em 0 1em !important;
}

.overviewanalyticstable {
	tr.has-confirmed {
		background-color: $confirmed-color;
	}
	tr.has-proposed {
		background-color: $proposed-color;
	}
	tr.has-cancelled {
		background-color: $cancelled-color;
	}

	.titleodd {
		background-color: #f2f2f2;
	}
	.titleeven {
		background-color: #fff;
	}

	.totalrow0 th {
		border-top: 1px solid #aaaaaa6b;
	}

	.clickable {
		cursor: pointer;
	}

	.number {
		text-align: right;
	}
}

.analyticstable, .overviewanalyticstable {
	&.bp3-html-table th.sortable {
		cursor: pointer;
		position: relative;
		padding-right: 15px;
	}

	&.bp3-html-table th.sort-asc::after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid black;
		/* Arrow color */
		transform: translateY(-50%);
	}

	/* Downward arrow to indicate descending sort order */
	&.bp3-html-table th.sort-desc::after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid black;
		/* Arrow color */
		transform: translateY(-50%);
	}
}

.costtable {

	/* Border around db colspan */
	&.bp3-html-table tr td:nth-last-child(4),
	&.bp3-html-table tr td:nth-last-child(2),
	&.bp3-html-table tr td:nth-last-child(1) {
		border-left: 1px solid #aaaaaa37;
	}

}

.lefttop-table {
	thead tr th {
		text-align: center;
	}
	tbody tr th:first-child {
		border-right: 1px solid #aaaaaa37;
	}
	tbody tr td {
		text-align: right;
	}
}

.pa-tooltip .bp3-popover2-content {
	background: white !important;
	color: black !important;
}

.pa-tooltip .bp3-popover2-arrow-fill {
	fill: white !important;
}

.configpage {
	ul {
		list-style: none;
		padding-left: 0;

		li {
			margin-top: 0.5em;
		}
	}

}

.loadingstatus {
	font-size: 1.3em;
}

.mr-0 {
	margin-right: 0em !important;
}

.mr-1 {
	margin-right: 0.25em !important;
}

.mr-2 {
	margin-right: 0.5em !important;
}

.ml-0 {
	margin-left: 0em !important;
}

.ml-1 {
	margin-left: 0.25em !important;
}

.ml-2 {
	margin-left: 0.5em !important;
}