body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.stylethiseditor {
	box-shadow: inset 0px 0px 8px 5px rgba(0, 0, 0, 0.04);
	display: block;
	padding: 10px;
	padding-bottom: 0px;
	padding-left: 28px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.Header {
	display: block;
	flex-direction: column;
	height: 5vh;
	background-color: #ab111d !important;
	color: white;
}

.Header-logo {
	height: 3.5vh;
}

.float-right {
	float: right;
}

.ProfileButton {
	background-image: url('https://www.gravatar.com/avatar/f03c5e909e7fa4da125c32d728a3cb0c?d=mm') !important;
	border-radius: 999em !important;
	width: 4.5rem;
	height: 4.5rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.46) !important;
	line-height: 1;
	font-size: 36px;
	position: relative;
	position: fixed;
	bottom: 32px;
	right: 32px;
	background-size: cover;
}

.Navbar {
	background-color: #282c34;
}

tr:nth-child(even) {
	background-color: #f2f2f2;
}

table {
	width: 100%;
}

p {
	line-height: normal !important;
}

.treemenu-link:hover {
	background-color: 'rgba(127,127,127,1)';
}

.muted-link:hover {
	text-decoration: none;
	color: inherit;
}

.muted-link {
	color: inherit;
}

.bp3-dialog-header .bp3-heading {
	overflow: visible !important;
}

.dashboard-table tr td,
.dashboard-table tr th {
	padding: 10px 14px;
	border: 1px solid white;
}

.dashboard-table .no-vpad {
	padding-top: 0;
	padding-bottom: 0;
}

.dashboard-table tr th {
	font-weight: bold;
	background-color: #f9f9f9;
}

.padded-table tbody td,
.padded-table thead th {
	margin: 5px !important;
	border: 10px solid black;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
	select,
	textarea,
	input[type='text'],
	input[type='password'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='date'],
	input[type='month'],
	input[type='time'],
	input[type='week'],
	input[type='number'],
	input[type='email'],
	input[type='url'] {
		font-size: 16px;
	}
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
	select,
	textarea,
	input[type='text'],
	input[type='password'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='date'],
	input[type='month'],
	input[type='time'],
	input[type='week'],
	input[type='number'],
	input[type='email'],
	input[type='url'] {
		font-size: 16px;
	}
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
	select,
	textarea,
	input[type='text'],
	input[type='password'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='date'],
	input[type='month'],
	input[type='time'],
	input[type='week'],
	input[type='number'],
	input[type='email'],
	input[type='tel'],
	input[type='url'] {
		font-size: 16px;
	}
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
	select,
	textarea,
	input[type='text'],
	input[type='password'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='date'],
	input[type='month'],
	input[type='time'],
	input[type='week'],
	input[type='number'],
	input[type='email'],
	input[type='tel'],
	input[type='url'] {
		font-size: 16px;
	}
}

.table-row {
	background-color: #fefefe;
	margin-bottom: 4px;
	border-radius: 4px;
	border-bottom: 2px solid #f0f0f0;
}

.table-col {
	padding: 5px;
}

.pulsingwrapper {
	margin-left: -20px;

	animation: pulse 1s infinite;
}

.pulsingheart {
	position: relative;
	width: 0px;
	height: 50px;
}

@keyframes pulse {
	10% {
		transform: scale(1.1);
	}
}

@keyframes pulsecolor {
	10% {
		background: #dd0000;
	}
}

.bp3-tag.bp3-intent-danger {
	background-color: #ac1c21 !important;
}

.flash-update, .flash-update div {
	animation: flashAnimation 1s;
}

@keyframes flashAnimation {
	0%,
	100% {
		background-color: transparent;
	}
	50% {
		background-color: rgb(174, 255, 136);
	} /* Flash color */
}

.my-block-marked {
	background-color: #fff0f0 !important;
}

.my-block-old {
	background-color: #fff8ec !important;
}
.my-block-link {
	color: #444;
	cursor: pointer;
}
.my-block-link:hover {
	color: #222;
}