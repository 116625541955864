@import url('https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css');

$color: #ff5b43;
$color-d: #333;
$color-l: lighten($color, 6%);

html,
body {
	min-height: 100vh;
}

.Router {
	background-color: #fff;
}

a {
	cursor: pointer;
}

.MainRightNav {
	margin: 0;
	padding: 0;
}

.NavSubMenu {
	list-style: none;
	padding: 0;
	margin: 0;
}

.wrapper {
	display: flex;
	border-right: 1px solid #eee;
	min-height: 100vh;
	max-width: 2000px;
}

nav {
	width: 20vw;
	min-width: 200px;
	max-width: 250px;
	background-color: #006157;
	padding-bottom: 75px;
	box-shadow: -81px -16px 69px -93px rgba(0, 0, 0, 0.2) inset;
	color: white;
}

nav header {
	position: relative;
	padding: 8px 0 0 8px;
	font-size: 16px;
	color: white;
	padding-bottom: 15px;
}

nav ul .NavSection {
	display: block;
	padding: 15px 14px 5px 17px;
	color: white;
	font-weight: 300;
	opacity: 0.5;
	letter-spacing: 1px;
	font-size: 16px;
	text-transform: uppercase;
}

nav ul a {
	position: relative;
	display: block;
	padding: 10px 15px 10px 15px;
	color: white !important;
	text-decoration: none !important;
}

nav ul a:hover {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	margin-left: 5px;
	padding-left: 10px;
	color: #fff !important;
	background-color: rgba(255, 255, 255, 0.1);
}
nav ul a {
	transition: all 0.1s ease;
}

nav ul a.active {
	font-weight: bold;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	margin-left: 5px;
	padding-left: 10px;
	color: #000 !important;
	border-right: 5px solid #ccc;
	background-color: #f9f9f9;
}

main {
	flex: 1;
	padding: 25px;
	background: #fff;
	box-shadow: 0px 0px 50px -7px rgba(0, 0, 0, 0.075);
}

main .desktopPad {
	margin-right: -10px;
}

main .topbar {
	height: 60px;
	margin: -25px -25px 5px -25px;
	padding: 0 10px;
	padding-top: 19px;
	display: block;
	background: #fff;
	border-bottom: 1px solid #ddd;
}

main .topright {
	margin-top: -4px;
	float: right;
}

@media (max-width: 1000px) {
	main .topbar {
		margin-bottom: 20px;
	}
}

.flex-grid {
	display: flex;
}

.flex-grid > div {
	flex: 1;
	margin: 0 20px 20px 0;
	padding: 15px;
	background: #fff;
}

.flex-grid > div:last-child {
	margin-right: 0;
}

.flex-grid h2 {
	margin: -15px -15px 15px -15px;
	padding: 12px 15px;
	font-size: 14px;
	font-weight: 400;
	border-bottom: 1px solid #ddd;
}

.flex-grid li {
	position: relative;
	margin: 0 0 10px;
	padding: 0 0 0 25px;
}

.flex-grid li:before {
	content: '\f00c';
	font: normal 16px fontawesome;
	top: 0;
	left: 0;
	color: #999;
}

.flexcontent {
	margin-top: -5px;
	display: block;
}
.MobileNavClose {
	float: right;
	color: $color;
	margin-top: -1px;
	margin-right: 20px;
	cursor: pointer;
}

.NavSubMenu {
	display: inline-block;
	margin-left: 15px;
}

.NavSubMenu li {
	float: left;
	margin-right: 4px;
	margin-top: 4px;
	margin-bottom: 10px;
}

.NavSubMenu li a {
	display: block;
	padding: 10px;
	padding-bottom: 12px;
	text-decoration: none;
	opacity: 0.7;
}

.NavSubMenu li a:hover {
	text-decoration: none;
	background-color: #f9f9f9;
	opacity: 0.9;
}

.NavSubMenu li a.active:hover {
	margin-top: 0px;
}

.NavSubMenu li a.active {
	background-color: #fff;
	border-bottom: 5px solid $color !important;
	margin-top: -3px !important;
	opacity: 1;
}

@media (max-width: 1130px) {
	.Below1130 {
		display: none;
	}
}
@media (max-width: 1000px) {
	.NavSubMenu li {
		padding: 0;
		margin: 0;
		width: 100%;
	}
	.NavSubMenu {
		width: 100%;
		max-width: calc(100vw - 50px);
		margin: 0;
	}
	.NavSubMenu li a {
		display: block;
		padding: 10px;
		padding-bottom: 12px;
		margin-bottom: 5px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.NavSubMenu li a.active {
		font-weight: bold;
		background-color: #eee;
	}

	.NavSubMenu li {
		clear: both;
		text-align: center;
	}

	nav {
		position: fixed;
		display: none !important;
		height: 100vh;
		width: 100%;
		overflow: scroll;
		z-index: 10;
		box-shadow: 2px -1px 50px 16px rgba(0, 0, 0, 0.56);
	}

	nav.visible {
		display: block !important;
	}

	.MobileHidden {
		display: none !important;
		visibility: hidden !important;
	}
}

.BurgerButton {
	z-index: 1;
	display: fixed;
	top: 0px;
	left: 10px;
}
@media (min-width: 1000px) {
	.hide-on-mobile {
		display: none !important;
	}
}
@media (min-width: 1000px) {
	.desktopPad {
		padding-top: 20px;
	}
}

@media (min-width: 1000px) {
	.OnlyMobileVisible {
		display: none;
		visibility: hidden;
	}

	.BurgerButton,
	.MobileNavClose {
		visibility: hidden !important;
		display: none !important;
	}
}

@media (max-width: 1000px) {
	.flex-grid {
		flex-direction: column;
	}

	.flex-grid > div {
		margin-right: 0;
	}
}

@media print {
	.topbar,
	.NavSubMenu,
	.MobileHidden,
	#searchTing,
	#sidemenu,
	.blockownership,
	.OnlyMobileVisible {
		display: none !important;
	}

	#maincontent {
		width: 100%;
	}
}
