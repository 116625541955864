$loyality-bar-legend-color: rgb(190, 190, 190);
$loyality-bar-legend-line-color: rgb(222, 222, 222);

.loyality-bar {

	width: 100%;
	position: relative;
	padding-right: 100px;

	.description {
		position: absolute;
		right: 0px;
		top: -30px;
		height: 70px;
		width: 90px;
		color: $loyality-bar-legend-color;
		font-weight: 600;

		.amount {
			position: absolute;
			width: 100px;
			text-align: center;
			top: 0px;
		}

		.level {
			position: absolute;
			width: 100px;
			text-align: center;
			top: 60px;
		}
	}

	.loyality-bar-content {
		display: flex;
		width: 100%;
		position: relative;
		height: 20px;
		margin: 20px 0 20px 0;
	}

	.crossbar {
		z-index: 1;
		border-left: 2px solid $loyality-bar-legend-line-color;
		height: 40px;
		position: absolute;
		top: 10px;
		transform: translateY(-50%);
	}

	.crossbarAmount {
		position: absolute;
		top: -30px;
		margin-left: -10px;
		width: 20px;
		text-align: center;
	}

	.crossbarLevel {
		position: absolute;
		top: 30px;
		margin-left: 1px;
		width: 20px;
		text-align: center;
	}

	.line {
		z-index: 2;
		height: 3px;
		margin-top: 4px;
		transition: width 0.3s ease-in-out;
	}

	.paid {
		background-color: rgb(0, 105, 0);
	}

	.confirmed {
		background-color: rgb(103, 197, 101);
	}

	.proposed {
		background-color: rgb(219, 171, 60);
	}

	.rest {
		background-color: rgb(222, 222, 222);
	}

	.pointcircle {
		z-index: 3;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		border: 0;
		position: sticky; /* go figure, need it for z-index to work */
	}

	.pointline {
		z-index: 3;
		height: 10px;
		width: 2px;
		border: 0;
		position: sticky; /* go figure, need it for z-index to work */
	}

	.loyality-bar-contract {
		display: flex;
		width: 100%;
		margin-top: -40px;
		position: relative;
		height: 45px;
		top: 0px;
	}

	.contractline {
		z-index: 1;
		height: 8px;
		width: 2px;
		border: 1px;
		margin-top: 2px;
	}
}
