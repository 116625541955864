div.TabMenu {
	border-bottom: 1px solid #ddd;
	height: 40px;
	margin: 0;
}

.TabMenu > ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.TabMenu > ul li {
	border-left: 1px solid grey;
	border-top: 1px solid grey;
	border-right: 1px solid grey;
	border-radius: 5px 5px 0 0;
	float: left;
	margin-right: 0.25em;
	box-sizing: border-box;
}

.TabMenu > ul li:not(.active) {
	opacity: 0.6;
}

.TabMenu > ul li.active {
	border-bottom: 5px solid #ac1c21 !important;
}

.TabMenu > ul li a {
	display: block;
	padding: 10px;
	text-decoration: none;
}
