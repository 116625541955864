.LoadingTrippelmBall {
	position: absolute;
	display: block;
	width: 200px;
	height: 200px;
	margin: 0 auto;
	animation-name: LoadingBounce;
	animation-duration: 0.6s;
	animation-iteration-count: infinite;
}
.LoadingTrippelmSlowBall {
	position: absolute;
	display: block;
	width: 200px;
	height: 200px;
	margin: 0 auto;
	animation-name: LoadingBounceSlow;
	animation-duration: 3s;
	animation-iteration-count: infinite;
}

@keyframes LoadingBounceSlow {
	0% {
		margin-top: 0px;
		width: 150px;
		margin-left: 25px;
		opacity: 0;
		height: 150px;
	}
	50% {
		margin-top: 50px;
		width: 200px;
		margin-left: 0px;
		opacity: 1;
		height: 200px;
	}
	60% {
		height: 150px;
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		margin-top: 0px;
		width: 150px;
		opacity: 0;
		margin-left: 25px;
		height: 150px;
	}
}
@keyframes LoadingBounce {
	0% {
		margin-top: 0px;
		width: 150px;
		margin-left: 25px;
		height: 150px;
	}
	50% {
		margin-top: 400px;
		width: 200px;
		margin-left: 0px;
		height: 200px;
	}
	60% {
		height: 150px;
	}
	100% {
		margin-top: 0px;
		width: 150px;
		margin-left: 25px;
		height: 150px;
	}
}

.LoadingRentmanBox {
	position: absolute;
	display: block;
	width: 200px;
	height: 200px;
	animation-name: LoadingBounceRentman;
	animation-duration: 0.6s;
	animation-iteration-count: infinite;
}

@keyframes LoadingBounceRentman {
	0% {
		margin-top: 465px;
		height: 200px;
	}

	20% {
		margin-top: 465px;
		height: 200px;
	}

	48% {
		margin-top: 600px;
		height: 100px;
	}
	80% {
		margin-top: 465px;
		height: 200px;
	}

	100% {
		margin-top: 465px;
		height: 200px;
	}
}

.LoadingContainer {
	width: 200px;
	margin: 0 auto;
	display: block;
	margin-top: 100px;
	zoom: 0.5;
}
@keyframes myOrbit {
	from {
		transform: rotate(0deg) translateX(15px) rotate(180deg);
	}
	to {
		transform: rotate(0deg) translateX(15px) rotate(-180deg);
	}
}
