.table-xpt {
	background-color: white;
}
.crosshatch {

  color: white;
  background: repeating-linear-gradient(
    45deg,
    rgba(0,0,0,0.5),
    rgba(0,0,0,0.1) 1px,
	transparent 2px,
	transparent 5px
  );
}